.about-us-screen {
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f4f4f4;
  padding: 60px 20px;
}

.about-us-section {
  text-align: center;
}

.about-us-title {
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.about-us-subtitle {
  font-size: 18px;
  color: #555;
  margin-bottom: 20px;
}

.about-us-description {
  font-size: 16px;
  color: #666;
  max-width: 800px;
  margin: 0 auto 40px auto;
}

.about-us-highlight p {
  font-size: 16px;
  line-height: 1.6;
  color: #444;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 15px;
}

.about-us-advantages {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
  padding: 0 20px;
  flex-wrap: wrap;
}

.advantage-item {
  text-align: center;
  width: 30%;
  margin-bottom: 40px;
  color: #333;
}

.advantage-icon {
  font-size: 40px;
  margin-bottom: 15px;
}

.advantage-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.advantage-description {
  font-size: 14px;
  color: #777;
  line-height: 1.5;
}

.about-us-footer {
  text-align: center;
  margin-top: 50px;
}

.footer-text {
  font-size: 16px;
  color: #666;
  font-weight: normal;
}

.social-media {
  margin-top: 40px;
}

.social-media h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.social-icons a {
  font-size: 30px;
  color: #333;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #0073e6;
}

@media (max-width: 767px) {
  .about-us-section {
    padding: 40px 10px;
  }

  .about-us-title {
    font-size: 28px;
  }

  .about-us-subtitle {
    font-size: 16px;
  }

  .about-us-description {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .about-us-advantages {
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }

  .advantage-item {
    width: 80%;
    margin-bottom: 30px;
  }

  .advantage-icon {
    font-size: 36px;
  }

  .advantage-title {
    font-size: 16px;
  }

  .advantage-description {
    font-size: 12px;
  }

  .footer-text {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .about-us-section {
    padding: 50px 15px;
  }

  .about-us-title {
    font-size: 32px;
  }

  .about-us-subtitle {
    font-size: 17px;
  }

  .about-us-description {
    font-size: 15px;
    margin-bottom: 35px;
  }

  .about-us-advantages {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
  }

  .advantage-item {
    width: 30%;
    margin-bottom: 40px;
  }

  .advantage-icon {
    font-size: 38px;
  }

  .advantage-title {
    font-size: 17px;
  }

  .advantage-description {
    font-size: 13px;
  }

  .footer-text {
    font-size: 15px;
  }
}

@media (min-width: 1025px) {
  .about-us-section {
    padding: 60px 20px;
  }

  .about-us-title {
    font-size: 36px;
  }

  .about-us-subtitle {
    font-size: 18px;
  }

  .about-us-description {
    font-size: 16px;
    margin-bottom: 40px;
  }

  .about-us-advantages {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
  }

  .advantage-item {
    width: 22%;
    margin-bottom: 40px;
  }

  .advantage-icon {
    font-size: 40px;
  }

  .advantage-title {
    font-size: 18px;
  }

  .advantage-description {
    font-size: 14px;
  }

  .footer-text {
    font-size: 16px;
  }
}