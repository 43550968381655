.how-it-works-section {
  background: linear-gradient(135deg, #f5f5f5, #eaeaea);
  padding: 60px 20px;

}

.container-2 {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.section-title {
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin-bottom: 40px;
}

.steps-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.step {
  flex: 1 1 calc(33.33% - 20px);
  max-width: calc(33.33% - 20px);
  padding: 20px;
  border-radius: 12px;
  background: #fff;
  transition: transform 0.3s ease-in-out;
}

.step:hover {
  transform: translateY(-10px);
}

.step-icon img {
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
}

.step-title {
  font-size: 24px;
  font-weight: bold;
  color: #222;
  margin-bottom: 10px;
}

.step-description {
  font-size: 16px;
  color: #555;
}


@media (max-width: 992px) {
  .step {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 576px) {
  .step {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .section-title {
    font-size: 28px;
  }
}