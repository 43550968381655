.header {
  background-color: #ffffff !important;
  color: #000;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}

.brand {
  font-size: 3.2rem;
  font-weight: bold;
  color: #0ea8b7;
  cursor: pointer;
}

.search-container {
  display: none;
  flex: 0 1 500px;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 4px 8px;
}

.search-container.visible-laptop {
  display: flex;
}

.search-container.visible-mobile {
  display: flex;
  flex: 1;
}

.search-input {
  width: 100%;
}

.search-icon {
  margin-left: 8px;
  cursor: pointer;
  color: #0ea8b7;
}

.nav-options {
  display: flex;
  align-items: center;
  gap: 16px;
}

.nav-options a {
  text-decoration: none;
  color: #111;
  font-weight: 500;
  font-size: 17px;
}

.profile-icon {
  color: #111;
}

.mobile-menu-button {
  display: none;
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.search-item {
  padding: 8px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
}

.search-item a {
  text-decoration: none;
  color: #111;
  font-size: 14px;
}

.search-item:last-child {
  border-bottom: none;
}

@media (max-width: 768px) {
  .nav-options {
    display: none;
  }

  .mobile-menu-button {
    display: block;
  }

  .search-container.visible-laptop {
    display: none;
  }

  .search-container.visible-mobile {
    display: flex;
    flex: 1;
  }
}