.profile-section {
  padding: 20px;
  border: 1px solid grey;
  border-radius: 8px;
}

.car-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.car-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 300px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}

.card-menu {
  align-self: flex-end;
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 20px;
  right: 0;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: flex;
  flex-direction: column;
  min-width: 150px;
  padding: 5px 0;
}

.dropdown-menu button {
  background: none;
  border: none;
  padding: 10px 15px;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  font-size: 1.4rem;
  color: #333;
}

.dropdown-menu button:hover {
  background-color: #f5f5f5;
}


.car-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 15px;
}

.car-info {
  text-align: left;
}

.car-info h2 {
  font-size: 1.7rem;
  color: #333;
}

.car-info p {
  font-size: 1.4rem;
  color: #777;
  margin: 5px 0;
}



@media (max-width: 768px) {
  .space-around {
    display: flex;
    flex-direction: column;
  }

  .car-card {
    width: 100%;
    min-width: 360px;
  }
}