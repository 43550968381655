.details-screen {
  background-color: #f3f4f6;
  padding: 20px;
  font-family: "Inter", sans-serif;
}

.container {
  margin: 0 auto;
  max-width: 1100px;
}

.car-title {
  font-size: 2rem;
  font-weight: 600;
  color: #1f2937;
}

.car-price {
  font-size: 1.8rem;
  color: #dc2626;
  margin-top: 10px;
}

.car-summary {
  font-size: 1.6rem;
  color: #0ea8b7;
  margin-top: 10px;
  font-weight: 600;
}

.image-wrapper {
  margin-bottom: 20px;
  width: 100%;
  height: 300px;
}

.main-image {
  width: 100%;
  border-radius: 8px;
}

.thumbnail-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  overflow-x: scroll;
}

.thumbnail-frame {
  cursor: pointer;
  padding: 3px;
  border: 2px solid transparent;
  border-radius: 6px;
  transition: border-color 0.3s;
}

.thumbnail-frame.active-thumbnail {
  border-color: #3b82f6;
}

.thumbnail-img {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  object-fit: cover;
}

.details-wrapper {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
}

.additional-info {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 15px;
}

.info-item {
  flex: 1 1 calc(50% - 10px);
}

.info-label {
  font-size: 1.7rem;
  color: #4b5563;
  margin-bottom: 4px;
}

.info-value {
  font-size: 3rem;
  font-weight: 500;
  color: #1f2937;
}

.actions {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.primary-button {
  background-color: #2563eb;
  color: #ffffff;
  padding: 10px 15px;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.primary-button:hover {
  background-color: #1d4ed8;
}

.secondary-button {
  background-color: transparent;
  color: #2563eb;
  padding: 10px 15px;
  border: 1px solid #2563eb;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.secondary-button:hover {
  background-color: #2563eb;
  color: #ffffff;
}


.chat-button {
  background-color: #0ea8b7;
  color: white;
  margin-right: 10px;
  padding: 9px;
  border: none;
  outline: none;
  border-radius: 5px;
}

.profile-button {
  background-color: transparent;
  color: #333;
  padding: 9px;
  border-radius: 5px;
  border: 1px solid #0ea8b7;
  color: #0ea8b7;
}

@media (max-width: 768px) {
  .image-wrapper {
    margin-bottom: 20px;
    width: 100%;
    height: 170px;
  }
}