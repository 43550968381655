.card {
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(73, 72, 72, 0.2), 0 6px 20px 0 rgba(161, 147, 147, 0.19);
}

.image-container img {
  min-height: 140px;
  max-height: 180px;
}

.details {
  text-align: left;
}

.car-name {
  color: #1a3d6d;
  font-size: 20px;
}

.car-price {
  color: #e53935;
  font-size: 2.125rem;
  font-weight: 700;
}

.badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ff5722;
  color: #ffffff;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  z-index: 999;
}

@media only screen and (max-width: 600px) {
  .image-container img {
    height: auto;
    min-height: auto;
  }
}