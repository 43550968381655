.banner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    min-height: 60vh;
    gap: 40px;
    background-color: #ecfdff;
}

.banner-content,
.banner-image {
    flex: 1;
    max-width: 45%;
}

.banner-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 5%;
}

.banner-image {
    display: flex;
    justify-content: center;
    margin-right: 5%;
}

.banner img {
    max-width: 90%;
    height: auto;
}

.banner .mt-4 {
    margin-top: 20px;
}

@media (max-width: 768px) {
    .banner {
        padding: 20px;
        flex-direction: column;
        align-items: left;
        text-align: left;
    }

    .banner-content,
    .banner-image {
        max-width: 100%;
        margin: 0;
    }

    .banner h2 {
        font-size: 3.2rem;
        margin-top: 64px;
    }

    .banner img {
        margin-top: 40px;
    }
}