.review-page {
  font-family: "Arial", sans-serif;
  background-color: #f8f9fa;
  min-height: 100vh;
  padding: 20px 0;
  height: auto;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 10px;
}

h2 {
  font-size: 35px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.description {
  font-size: 18px;
  line-height: 1.6;
  color: #666;
}

.rating-section {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.rating-header {
  display: flex;
  align-items: center;
  gap: 20px;
}

.rating-large {
  font-size: 48px;
  font-weight: bold;
  color: #333;
}

.bar-graph {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.graph {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rating-label {
  width: 80px;
  font-size: 16px;
  font-weight: 500;
  color: #444;
}

.bar-container {
  flex-grow: 1;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
}

.bar {
  height: 100%;
  border-radius: 10px;
  transition: width 0.5s ease;
}

.bar-count {
  font-size: 18px;
  font-weight: 500;
  color: #555;
}

.review-summary {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.review-summary h4 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.review-summary p {
  font-size: 16px;
  line-height: 1.5;
  color: #555;
}

.customer-reviews {
  margin-top: 40px;
}

.customer-reviews h3 {
  font-size: 28px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}

.review-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.review-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.review-card h5 {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.review-text {
  font-size: 16px;
  font-style: italic;
  color: #666;
  margin: 10px 0;
}

@media (max-width: 768px) {
  .review-page {
    margin-top: 60px;
  }

  .rating-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .rating-large {
    font-size: 36px;
  }

  .review-card {
    margin-bottom: 15px;
  }
}