.breadcrumb {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.breadcrumb ul {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.breadcrumb li {
  margin-right: 8px;
}

.breadcrumb li a {
  text-decoration: none;
  color: #111;
  font-size: 15px;
}

.breadcrumb li a:hover {
  text-decoration: underline;
}

.breadcrumb li::after {
  content: ">";
  margin-left: 8px;
  color: #6c757d;
}

.breadcrumb li:last-child::after {
  content: "";
}

.breadcrumb li span {
  color: #6c757d;
}