.faq-section-modern {
  background: linear-gradient(135deg, #ffffff, #f9f9f9);
  padding: 80px 20px;
  font-family: 'Poppins', sans-serif;
  background-color: red;
}

.faq-container {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

.faq-title-modern {
  font-size: 36px;
  font-weight: 600;
  color: #222;
  margin-bottom: 50px;
}

.faq-list-modern {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.faq-item-modern {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: left;
  position: relative;
  overflow: hidden;
}

.faq-item-modern:hover {
  border-color: #0ea8b7;
  background: #f7faff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.faq-header-modern {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-question-modern {
  font-size: 18px;
  font-weight: 500;
  color: #333;
}

.faq-toggle-icon {
  font-size: 20px;
  font-weight: bold;
  color: #0ea8b7;
  transition: transform 0.3s ease;
}

.faq-item-modern.active .faq-toggle-icon {
  transform: rotate(180deg);
}

.faq-answer-modern {
  margin-top: 15px;
  font-size: 16px;
  color: #555;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .faq-title-modern {
    font-size: 28px;
  }

  .faq-question-modern {
    font-size: 16px;
  }

  .faq-item-modern {
    padding: 15px;
  }
}