.add-car-container {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 2rem;
}

.steps-container {
  flex: 1;
}

.steps {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
  line-height: 40px;
}

.multi-step {
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.completed {
  color: rgb(24, 179, 24);
}

.incomplete {
  color: rgb(222, 49, 99);
}

.form-container {
  flex: 2;
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
}

.back-link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  color: #333;
}

.form-title {
  margin: 1rem 0;
  font-size: 24px;
  font-weight: 600;
}

.add-car-form {
  margin-top: 2rem;
}



.form-actions {
  display: flex;
  justify-content: space-between;
}

.next-btn,
.prev-btn {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
}

.next-btn {
  background-color: rgb(207, 42, 111);
  color: #fff;
}

.prev-btn {
  background-color: #f9f9f9;
  color: #333;
}

.image-preview {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.image-container {
  position: relative;
}

.additional-image {
  width: 150px;
  border-radius: 4px;
}

.delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  color: rgb(207, 42, 111);
  font-size: 1.2rem;
}

@media only screen and (max-width: 600px) {
  .add-car-container {
    flex-direction: column;
  }

  .steps-container {
    order: -1;
    margin-bottom: 1rem;
  }

  .steps {
    flex-direction: row;
    justify-content: space-between;
  }

  select {
    width: 87%;
    padding: 10px;
    margin-bottom: 40px;
  }
}