.cars-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
}

@media (max-width: 768px) {
  .cars-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card {
  padding: 1rem;
  border: 1px solid #ddd;
  text-align: center;
  border-radius: 10px;
}

/* COLOR: #fff5f5 */