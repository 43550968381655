.contact-screen {
  font-family: 'Arial', sans-serif;
  padding: 50px 20px;
  background-color: #f8f9fa;
  color: #212529;
}

.contact-title {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
  color: #343a40;
}

.contact-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-info {
  flex: 1;
  min-width: 280px;
  padding: 20px;
  border: 2px solid #dee2e6;
  border-radius: 10px;
  background-color: #ffffff;
}

.contact-info h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.contact-info p {
  font-size: 16px;
  margin-bottom: 10px;
}

.contact-info h3 {
  font-size: 20px;
  margin-top: 20px;
}

.social-icons {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.social-icons a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 18px;
  color: #ffffff;
  border-radius: 50%;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.social-icons a:hover {
  background-color: darkcyan;
}

.feedback-form {
  flex: 1;
  min-width: 280px;
  padding: 20px;
  border: 2px solid #dee2e6;
  border-radius: 10px;
  background-color: #ffffff;
}

.feedback-form h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.feedback-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.feedback-form input,
.feedback-form textarea {
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  background-color: #e9ecef;
}

.feedback-form input:focus,
.feedback-form textarea:focus {
  outline: none;
  border-color: #0ea8b7;
  background-color: #ffffff;
}

.feedback-form button {
  padding: 12px;
  font-size: 16px;
  color: #ffffff;
  background-color: #0ea8b7;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.feedback-form button:hover {
  background-color: darkcyan;
}

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
  }

  .contact-info,
  .feedback-form {
    width: 100%;
  }
}