.box {
    border: 1px solid #f7fefe;
    background-color: #fff;
    border-radius: 7%;
    display: flex;
    justify-content: center;
    text-align: center;
}

.advertisement-section {
    background-color: #ebf0f2;
}