body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.home-container {
  display: flex;
  height: 100vh;
}

.sidebar {
  flex: 1;
  background: #f7f7f7;
  overflow-y: auto;
  max-width: 300px;
  border-right: 1px solid #ddd;
}

.sidebar-header {
  padding: 10px;
  background: #008069;
  color: #fff;
  text-align: center;
}

.user-list {
  padding: 10px;
}

.user-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.user-list-item:hover {
  background: #f1f1f1;
}

.avatar {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  color: #008069;
}

.user-info {
  flex: 1;
}

.user-name {
  font-weight: bold;
}

.user-status {
  font-size: 0.9em;
  color: gray;
  display: flex;
  align-items: center;
}

.online-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #00c853;
  margin-right: 5px;
}

.chat-container {
  flex: 3;
  display: flex;
  flex-direction: column;
  background: #e5ddd5;
}

.chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: #008069;
  color: white;
}

.chat-header-left {
  display: flex;
  align-items: center;
}

.back-icon {
  cursor: pointer;
  margin-right: 10px;
}

.messages {
  flex: 1;
  padding: 15px;
  overflow-y: auto;
}

.message {
  margin: 5px 0;
  padding: 10px 15px;
  border-radius: 8px;
  max-width: 60%;
}

.incoming {
  background: #fff;
  align-self: flex-start;
}

.outgoing {
  background: #dcf8c6;
  align-self: flex-end;
}

.message-input-container {
  display: flex;
  padding: 10px;
  background: #f0f0f0;
  border-top: 1px solid #ddd;
}

.message-input-container input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
}

.message-input-container button {
  margin-left: 10px;
  padding: 10px 20px;
  background: #008069;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.online-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: gray;
  margin-right: 5px;
}

.online-indicator.active {
  background: #00c853;
}


@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    max-width: 100%;
  }
}

.home-container {
  display: flex;
  height: calc(100vh - 120px);
}

.sidebar {
  width: 30%;
  border-right: 1px solid #ddd;
  overflow-y: auto;
}

.chat-screen-container {
  width: 70%;
}

.sidebar-header {
  padding: 16px;
  border-bottom: 1px solid #ddd;
}

.user-list {
  padding: 8px;
}

.user-list-item {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.user-list-item:hover {
  background-color: #f0f0f0;
}

.avatar {
  margin-right: 8px;
}

.user-info {
  flex: 1;
}

.user-name {
  font-weight: bold;
}

.user-status {
  font-size: 12px;
  color: #666;
}

.online-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ccc;
  display: inline-block;
  margin-right: 4px;
}

.online-indicator.active {
  background-color: #4caf50;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat-header {
  padding: 16px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
}

.chat-header-left {
  display: flex;
  align-items: center;
}

.back-icon {
  cursor: pointer;
  margin-right: 8px;
}

.messages {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
}

.message {
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
  max-width: 60%;
}

.message.outgoing {
  background-color: #dcf8c6;
  margin-left: auto;
}

.message.incoming {
  background-color: #f0f0f0;
  margin-right: auto;
}

.message-input-container {
  padding: 16px;
  border-top: 1px solid #ddd;
  display: flex;
}

.message-input-container input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 8px;
}

.message-input-container button {
  padding: 8px 16px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
  }

  .chat-screen-container {
    display: none;
  }
}