.update-profile-screen .avatar-container {
  position: relative;
  display: inline-block;
}

.update-profile-screen .avatar-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 3px solid #ddd;
}

.update-profile-screen .camera-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #007bff;
  color: #fff;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
}

.update-profile-screen .form-control {
  font-size: 1.2rem;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  width: 100%;
}

.update-profile-screen .editable-field {
  border-bottom: 1px solid #007bff;
}