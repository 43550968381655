.saved-cars-section {
  background-color: #f9f9f9;
  padding: 20px 0;
  font-family: Arial, sans-serif;
}

.saved-cars-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 20px;
}

.saved-cars-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.saved-cars-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.saved-car-row {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.saved-car-image {
  width: 120px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
}

.saved-car-details {
  flex: 1;
  margin-left: 15px;
}

.saved-car-name {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.saved-car-price {
  font-size: 16px;
  color: #007bff;
  margin-bottom: 5px;
}

.saved-car-info {
  font-size: 14px;
  color: #555;
}

.saved-car-location {
  font-size: 14px;
  color: #888;
}

.saved-car-remove-btn {
  background-color: #ff4d4f;
  color: #fff;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.saved-car-remove-btn:hover {
  background-color: #d9363e;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-btn {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
  padding: 8px 12px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
}

.pagination-btn.active-page {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.pagination-btn:hover {
  background-color: #f0f0f0;
}


@media screen and (max-width: 768px) {
  .saved-car-row {
    flex-direction: column;
    text-align: center;
  }

  .saved-car-image {
    width: 100%;
    height: 150px;
    margin-bottom: 10px;
  }

  .saved-car-details {
    margin: 10px 0;
  }

  .saved-car-name {
    font-size: 16px;
  }

  .saved-car-price {
    font-size: 15px;
  }

  .saved-car-info,
  .saved-car-location {
    font-size: 13px;
  }

  .pagination-btn {
    padding: 6px 10px;
    margin: 2px;
  }

  .saved-car-remove-btn {
    padding: 8px 12px;
    font-size: 13px;
  }
}